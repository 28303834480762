<template>
  <v-form>
    <v-overlay :value="overlay">
     <v-progress-circular
       indeterminate
       size="64"
     ></v-progress-circular>
   </v-overlay>
   <v-alert
      :value="errorMessage !== ''"
      border="top"
      colored-border
      type="error"
      elevation="2"
       >
         {{ errorMessage }}
     </v-alert>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="firstname"
          outlined
          label="First name"
          prepend-icon="mdi-account"
          hide-details="auto"
          :error="firstname == '' && showError"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
      <v-text-field
        v-model="lastname"
        outlined
        label="Last name"
        prepend-icon="mdi-account"
        hide-details="auto"
        :error="lastname == '' && showError"
      ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="email"
          outlined
          label="Email address"
          prepend-icon="mdi-email"
          hide-details="auto"
          :error="email == '' && showError"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="password"
          outlined
          label="Password"
          prepend-icon="mdi-lock"
           hide-details="auto"
           :error="password == '' && showError"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-btn
      @click="addStaff()"
      x-large
      color="primary"
      bottom
      right
      absolute
      :class="{ 'v-btn--mobile absolute' : $vuetify.breakpoint.xsOnly }"
    >
      Add Staff
    </v-btn>
  </v-form>
</template>
<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'addStaff',
    components: {

    },
    data() {
      return {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        overlay: false,
        errorMessage: '',
        showError: false
      }
    },
    methods: {
      ...mapActions(['createStaff']),
      addStaff() {
        this.overlay = true;

          this.createStaff({
            first_name: this.firstname,
            last_name: this.lastname,
            email: this.email,
            password: this.password
          })
          .then(() => {
            this.overlay = false;
          }, () => {
            this.showError = true;
            this.overlay = false;
          });

      }
    }
  }
</script>
<style lang="scss" scoped>

</style>
